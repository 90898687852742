import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

function Intro() {
  const { t } = useLocalization()

    return (
      <Section>
        <Video 
          autoPlay={true}
          poster="/img/breath.png"
          muted
          loop
        >
          <source src="/video/breath.mp4" type="video/mp4" />
        </Video>
        <Overlay>
          <div></div>
          <div>
            <Title>{t("Living")}</Title>
            <Title>{t("with endometriosis")}</Title>
            <Title $isColored={true}>{t("isn’t your entire story")}</Title>
            <Text>{t("We believe every woman with endometriosis and other chronic gynecological conditions deserves more than just survival.")}</Text>
            <Text $isColored={true}>{t("You deserve to thrive.")}</Text>
            <Text>{t("ENDOless isn’t just another app — it’s your guide to living beyond the limits of endometriosis. One symptom at a time.")}</Text>
            <ButtonLink
              href="https://survey.endoless.tech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t("Join the waitlist")}</span>
              <StyledIcon viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.65234 17.2168L14.4691 8.4M14.4691 8.4V15.7595M14.4691 8.4H7.10962" stroke="white" strokeWidth="1.4075" strokeLinecap="round" strokeLinejoin="round"/>
              </StyledIcon>
            </ButtonLink>
          </div>
        </Overlay>
      </Section>
    )
}

const Section = styled("section")`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
`

const Overlay = styled("div")`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  background: rgba(255, 255, 255, 0.82);
  position: relative;
  top:0;
  left:0;
  z-index: 1;
  padding-top: 10rem;
  padding-bottom: 12.5rem;

  @media (max-width: 768px) {
    padding-top: 6em;
    padding-bottom: 8.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Title = styled("p")`
  color: ${props => props?.$isColored ? "#685ADD" : "#1C1C1C"};
  font-family: Exo;
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1875rem;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`

const Text = styled("p")`
  color: ${props => props?.$isColored ? "#685ADD" : "#1C1C1C"};
  font-size: 1.5rem;
  font-weight: ${props => props?.$isColored ? "700" : "400"};
  margin: 0;
  padding-top: .5rem;
  padding-right: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const ButtonLink = styled("a")`
  position: absolute;
  margin-top: 2.5rem;
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  padding: 0.75rem 2rem;
  border-radius: 2.625rem;
  border: 1px solid #685ADD;
  background: #685ADD;
  display: flex;
  flex-direction: row;
  align-items: center;
    &:hover {
      border-radius: 2.625rem;
      border: 1px solid #685ADD;
      background: #FCFAF6;
      color: #685ADD;
    }
    &:hover svg path {
      stroke: #685ADD;
  }
`

const StyledIcon = styled("svg")`
  width: 22px;
  height: 22px;
`

const Video = styled("video")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default Intro;

