import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

function Journey() {
    const { t } = useLocalization()

    return (
        <Section>
            <Title>
                <Span>{t("Your journey. ")}</Span><Span $isColored={true}>{t("Your power.")}</Span>
            </Title>
            <Container>
                <Image 
                    src="/img/journey.png"
                    width=""
                    height=""
                />
                <Texts>
                    <Text>
                        {t("Every woman deserves to live a life free from constant pain and lifestyle limitations. And it starts with better understanding, tracking, and managing her condition.")}
                    </Text>
                    <Text>
                        {t("ENDOless is designed to help you regain control")}<br></br>
                        {t("over your life.")}
                    </Text>
                    <Text>
                        {t("By tracking symptoms, cycles, and triggers, we help you understand your body and provide insights tailored to your unique journey with endometriosis, PCOS, adenomyosis and other gynecological conditions.")}
                    </Text>
                    <ImageLine 
                        src="/img/svg/lightGreenLine.svg"
                        width=""
                        height=""
                    />
                </Texts>
            </Container>
        </Section>
    )
}

const Section = styled("section")`
    margin: 8rem 2.5rem 11rem 3.75rem;

    @media (max-width: 768px) {
        margin: 4rem 1.5rem 5rem 1.5rem;
    }
`
const Title = styled("p")`
    margin: 0;
    margin-bottom: 4.75rem;

    @media (max-width: 768px) {
        margin-bottom: 1.75rem;
    }
`
const Span = styled("span")`
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1875rem;
    color: ${props => props?.$isColored ? "#685ADD" : "#1C1C1C"};

    @media (max-width: 768px) {
        font-size: 2.25rem;
    }
`
const Container = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`
const Texts = styled("div")`
    max-width: 48%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`
const Text = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: .75rem;
`
const Image = styled("img")`
    @media (max-width: 768px) {
        display: none;
    }
`
const ImageLine = styled(Image)`
    margin-top: 3rem;
    margin-right: -3rem;
`

export default Journey;