import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";
import Employe from "./Employe";

function Staff({ team }) {
    const { t } = useLocalization()
    
    return (
        <Section>
            <TextContainer>
                <Span $isColored={true}>{t("Team who works hard  to bring ENDOless to life. ")}</Span><Span>{t("Focused on creating a tool that empowers women to take control of their well-being.")}</Span>
            </TextContainer>
            <TeamContainer>
                {team.map((person, i) => 
                    <Employe
                        key={i}
                        person={person}
                    />
                )}
            </TeamContainer>
        </Section>
    )
}

const Section = styled("section")`
    background: #F2ECFE;
    display: flex;
    flex-direction: column;
    padding: 0 2.5rem;
`
const TextContainer = styled("div")`
    max-width: 54%;
    align-self: flex-end;
    margin-bottom: 6.5rem;

    @media (max-width: 430px) {
        max-width: 100%;
    }
`
const Span = styled("span")`
    color: ${props => props?.$isColored ? "#604AFF" : "#122130"};
    font-family: Exo;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
`
const TeamContainer = styled("div")`
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr; 
    grid-template-rows: 1fr 1fr;
    gap: 5em 1.25em;
    grid-auto-flow: row;
    justify-content: space-between; 
    align-content: space-evenly; 
    justify-items: center; 
    align-items: center;
    padding-bottom: 10.5rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        align-items: start;
        gap: 3em 1.25em;
    }

    @media (max-width: 430px) {
        grid-template-columns: 1fr 1fr;
        gap: 1.5em 1.25em;
        padding-bottom: 5.25rem;
    }

`
export default Staff;