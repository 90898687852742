import React from "react";
import styled from "styled-components";

function ColoredText({ text, withCitation, isFirst, isLast }) {
    return(
        <>
            {(withCitation && isFirst) && (
                <ImgSvg
                    src="/img/svg/citation.svg"
                    width=""
                    height=""
                    alt="citation"
                />
            )}
            <P $isLast={isLast}>{text}</P>
        </>
    )
}

const P = styled("p")`
    padding: 0;
    color: #685ADD;
    text-align: center;
    font-family: Exo;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: ${props => props?.$isLast ? "3rem 0" : "0"};

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`
const ImgSvg = styled("img")`
    margin-bottom: 1.5rem;
`


export default ColoredText;