import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../utils/LocalizationContext";
import Warrior from "./components/warrior/Warrior";
import Text from "./components/text/Text";
import ColoredText from "./components/text/ColoredText";
import Staff from "./components/team/Staff";

function Team() {
  const { t } = useLocalization()
  const FIRST_TEXTS = [
    t("I started experiencing pain shortly after my period began. At first, it was bearable—or at least I convinced myself it was, because everyone told me, “That’s just what being a woman feels like.”"),
    t("But it wasn’t normal. I was always exhausted, my mental health spiraled, my digestion stopped working properly, and the pain… it kept getting worse. It wasn’t tied to my period anymore; it could hit anytime, leaving me feeling completely out of control of my own body."),
    t("I thought I was alone in this, but I wasn’t. When I started researching, I discovered how many women are living the same nightmare—suffering from endometriosis, fibroids, PCOS, adenomyosis, and other women’s chronic conditions that are under-researched, invisible, and ignored. I knew I had to take my life back—and help other women do the same.")
  ]
  const FIRST_COLORED_TEXTS = [
    t("For years, I endured debilitating pain and life-"),
    t("altering symptoms, being told,"),
    t("“This is just what being a woman feels like.”"),
    t("No, it isn’t.")
  ]
  const SECOND_TEXTS = [
    t("After years of struggle, I was officially diagnosed with endometriosis and had surgery. I found out that my bladder, uterus, and colon had fused together because of the disease. No, it wasn’t in my head, as I’d been led to believe."),
    t("But the surgery wasn’t a magic fix. I felt better, but I wasn’t great. It was still hard to explain to my colleagues and management why I couldn’t always attend meetings or travel for work. How do you say, “I might faint from the pain, I barely sleep, and I’m mentally drained,” without sounding weak or unprofessional?"),
    t("At some point, I’d had enough. Enough of hiding, pretending I was fine, gaslighting myself, and feeling guilty for not doing more. I left my corporate career and decided to create ENDOless. This app isn’t just about tracking symptoms; it’s about helping women reclaim their lives.")
  ]
  const SECOND_COLORED_TEXTS = [
    t("For myself."),
    t("And for every woman who’s ever been told,"),
    t("“This is just what being a woman feels like.”"),
    t("Because I CAN do more. And so can you.")
  ]
  const EMPLOYEES = [
    {
      photo: '/img/team/Andrey.png',
      name: "Andrei Kuzmin",
      position: t("Chief Technology  Officer")
    },
    {
      photo: '/img/team/Natasha.png',
      name: "Natasha Cristin",
      position: t("Chief Marketing Officer")
    },
    {
      photo: '/img/team/Karina.png',
      name: "Karina Ismagilova",
      position: t("Collaborator for Product Development")
    },
    {
      photo: '/img/team/Aliya.png',
      name: "Aliya Zolotova",
      position: t("Product Designer")
    },
    {
      photo: '/img/team/Noemie.png',
      name: "Noémie Letenneur",
      position: t("Fullstack Python Developer")
    },
    {
      photo: '/img/team/Anastasiia.png',
      name: "Anastasiia Smirnova",
      position: t("iOS Developer")
    },
    {
      photo: '/img/team/Maria.png',
      name: "Maria Filandrova",
      position: t("AI Researcher")
    },
    {
      photo: '/img/team/Mirvet.png',
      name: "Mirvet Mtimet",
      position: t("Project Manager")
    },
  ]
  return (
    <>
      <H1><Span $colored={true}>{t("The Team")}</Span><br></br><Span>{t("behind ENDOless")}</Span></H1>
      <Warrior />
      <TextSection>
        {FIRST_TEXTS.map((text, i) => 
          <Text 
            key={i}
            text={text}
            isFirst={i === 0}
          />
        )}
      </TextSection>
      <ColoredTextSection>
          {FIRST_COLORED_TEXTS.map((text, i) => 
            <ColoredText 
              key={`F_C-${i}`}
              text={text}
              isFirst={i === 0}
              isLast={i === (FIRST_COLORED_TEXTS.length - 1)}
              withCitation
            />          
          )}
      </ColoredTextSection>
      <TextSection>
        {SECOND_TEXTS.map((text, i) => 
          <Text 
            key={`${i}ST`}
            text={text}
            isFirst={i === 0}
          />
        )}
      </TextSection>
      <ColoredTextSection>
          {SECOND_COLORED_TEXTS.map((text, i) => 
            <ColoredText 
              key={`S_C-${i}`}
              text={text}
              isFirst={i === 0}
              isLast={i === (FIRST_COLORED_TEXTS.length - 1)}
            />          
          )}
      </ColoredTextSection>
      <LineContainer>
        <ImgSvg
          src="/img/svg/brightGreenLine.svg"
          width=""
          height=""
          alt="brightGreenLine"
        />
      </LineContainer>
      <Staff 
        team={EMPLOYEES}
      />
    </>
  );
}

const H1 = styled("h1")`
  font-family: Exo;
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin: 0;
  padding: 10.5rem 2.5rem;
  background: #F2ECFE;

  @media (max-width: 768px) {
    padding-bottom: 5rem;
  }
`
const Span = styled("span")`
  color: ${props => props?.$colored ? "#685ADD" : "#252525"};
`
const TextSection = styled("div")`
  background: #F2ECFE;
  padding: 0 2.5rem;
  padding-bottom: 3rem;
`
const ColoredTextSection = styled("div")`
  background: #F2ECFE;
  padding: 0 2.5rem;
  padding-bottom: 3rem;
  color: #685ADD;
  text-align: center;
  font-family: Exo;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const LineContainer = styled("div")`
  background: #F2ECFE;
  padding-bottom: 4rem;
`
const ImgSvg = styled("img")``

export default Team;