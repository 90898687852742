import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

const LOGO_ARRAY = [
    {
        title: "Fighters",
        src: "/img/icons/fighters_logo.png",
    },
    {
        title: "CCI",
        src: "/img/icons/CCI_logo.png",
    },
    {
        title: "SKEMA",
        src: "/img/icons/SKEMA_logo.png",
    },
    {
        title: "STATION F",
        src: "/img/icons/STATION_logo.png",
    },
    {
        title: "ISSY",
        src: "/img/icons/issy_logo.png",
    },
    {
        title: "POSITIV",
        src: "/img/icons/positiv_logo.png",
    },
    {
        title: "MS_Startup",
        src: "/img/icons/MS_Startup_logo.png",
    },
    {
        title: "Region ile de France",
        src: "/img/icons/Region_logo.png",
    },
    {
        title: "Entrepreneur",
        src: "/img/icons/entrepreneur.png",
    }
]

function Partners() {
  const { t } = useLocalization()

  return (
      <Section>
        <Title>{t("Partners")}</Title>
        <LogoContainer>
            {LOGO_ARRAY.map((logo, i) => 
                <img 
                    src={logo.src}
                    width=""
                    height=""
                    alt={`${logo.title} logo`}
                    key={i}
                />
            )}
        </LogoContainer>
      </Section>
  );
}

const Section = styled("section")`
    margin: 2rem 0;
`
const Title = styled("p")`
    color: #1C1C1C;
    text-align: center;
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        font-size: 2.25rem;
    }
`
const LogoContainer = styled("div")`
    margin: 0 2.5rem;
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    justify-items: center;

    @media (max-width: 768px) {
        margin: 0 1.5rem;
        grid-template-columns: repeat(3, auto);
        gap: .5rem;
        justify-content: space-evenly;

        & img {
            width: 90%;
        }
    }
`

export default Partners;