import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

function Steps() {
    const { t } = useLocalization()

    const CONTENT = [
        {
            number: 1,
            title: t("Track"),
            text: t("Monitor pain intensity, mood, lifestyle choices and other symptoms")
        },
        {
            number: 2,
            title: t("Predict & Plan"),
            text: t("Receive personalized AI-predictions for your pain and other symptoms and plan your life accordingly")
        },
        {
            number: 3,
            title: t("Identify"),
            text: t("Discover what really helps to minimize symptoms   and works best for you")
        },
        {
            number: 4,
            title: t("Report"),
            text: t("Generate detailed reports to share with your doctor")
        },
    ]
    
    return (
        <Section>
            <MainContent>
                <TextContainer>
                    <Title>{t("How it works")}</Title>
                    <Title $isColored={true}>{t("in 4 simple steps")}</Title>
                    {CONTENT.map((item, i) => 
                        <Point key={i}>
                            <Number>{item.number}</Number>
                            <PointText>
                                <Text $isBolded={true}>{item.title}</Text>
                                <Text>{item.text}</Text>
                            </PointText>
                        </Point>
                    )}
                </TextContainer>
                <Image
                    src="/img/svg/dashboard.svg"
                    width=""
                    height=""
                />
            </MainContent>
            <ButtonLink
              href="https://survey.endoless.tech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t("Join the waitlist")}</span>
              <StyledIcon viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.65234 17.2168L14.4691 8.4M14.4691 8.4V15.7595M14.4691 8.4H7.10962" stroke="white" strokeWidth="1.4075" strokeLinecap="round" strokeLinejoin="round"/>
              </StyledIcon>
            </ButtonLink>
        </Section>
    )
}

const Section = styled("section")`
    margin-left: 5rem;
    margin-right: 2.5rem;

    @media (max-width: 768px) {
        margin: 0 1.5rem;
    }
`
const MainContent = styled("div")`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`
const TextContainer = styled("div")`

`
const Title = styled("p")`
    margin: 0;
    margin-bottom: ${props => props?.$isColored ? "4.5rem" : ""};
    color: ${props => props?.$isColored ? "#685ADD" : "#1C1C1C"};
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4rem;
    letter-spacing: -0.125rem;

    @media (max-width: 768px) {
        font-size: 2.25rem;
        margin-bottom: ${props => props?.$isColored ? "2.5rem" : ""};
    }
`
const Point = styled("div")`
    display: flex;
    align-items: flex-start;
`
const Number = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: #F2ECFE;
    color: #685ADD;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
`
const PointText = styled("div")`
    margin-bottom: 1.5rem;
`
const Text = styled("p")`
    width: 42rem;
    margin: 0;
    color: #1C1C1C;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: ${props => props?.$isBolded ? "700" : "300"};
    margin-bottom: ${props => props?.$isBolded ? ".5rem" : ""};
    line-height: 1.625rem; 

    @media (max-width: 430px) {
        width: 14rem;
    }
`
const Image = styled("img")`
    width: 40.125rem;
    height: 40.125rem;

    @media (max-width: 768px) {
        display: none;
    }
`
const ButtonLink = styled("a")`
  margin-top: 2.5rem;
  margin-bottom: 8rem;
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  padding: 0.75rem 2rem;
  border-radius: 2.625rem;
  border: 1px solid #685ADD;
  background: #685ADD;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
    &:hover {
      border-radius: 2.625rem;
      border: 1px solid #685ADD;
      background: #FCFAF6;
      color: #685ADD;
    }
    &:hover svg path {
      stroke: #685ADD;
  }

    @media (max-width: 768px) {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
    }
`

const StyledIcon = styled("svg")`
  width: 22px;
  height: 22px;
`

export default Steps;