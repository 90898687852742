import React, { useState }  from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";
import Element from "./Element";

function Questions() {
    const { t } = useLocalization()
    const [ isOpenQuestion1, setOpenQuestion1 ] = useState(false)
    const [ isOpenQuestion2, setOpenQuestion2 ] = useState(false)
    const [ isOpenQuestion3, setOpenQuestion3 ] = useState(false)
    const [ isOpenQuestion4, setOpenQuestion4 ] = useState(false)
    const [ isOpenQuestion5, setOpenQuestion5 ] = useState(false)
    const [ isOpenQuestion6, setOpenQuestion6 ] = useState(false)
    const [ isOpenQuestion7, setOpenQuestion7 ] = useState(false)

    const questions = [ isOpenQuestion1, isOpenQuestion2, isOpenQuestion3, isOpenQuestion4, isOpenQuestion5, isOpenQuestion6, isOpenQuestion7 ]
    const isFullWidth = questions.some(question => question === true)

    return (
        <Section>
            <Title>{t("Your journey,")}</Title>
            <Title>{t("Your ")}<ISpan>{t("questions")}</ISpan>{t(" — answered")}</Title>
            <Container>
                <Image 
                    src="/img/svg/purpleLine.svg"
                    width=""
                    height=""
                />
                <QuestionsContainer>
                    <Element
                        title={t("Is ENDOless only for women with a formal diagnosis?")}
                        text={t("Not at all! Whether you’ve been officially diagnosed or just suspect you may have endometriosis or another gynecological condition, ENDOless can help you understand your body better and prepare for conversations with healthcare providers, potentially leading to a faster diagnosis.")}
                        isOpen={isOpenQuestion1}
                        toggleFAQ={() => setOpenQuestion1(!isOpenQuestion1)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("Is ENDOless focused only on endometriosis?")}
                        text={t("Not at all! Our solution also supports women managing other gynecological chronic conditions like PCOS, adenomyosis, fibroids, and more. ENDOless provides symptom management tools and personalized insights to improve your everyday life.")}
                        isOpen={isOpenQuestion2}
                        toggleFAQ={() => setOpenQuestion2(!isOpenQuestion2)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("When will ENDOless become available to download?")}
                        text={t("The first version of the app will be available for download in early 2025. We’ll continue rolling out updates and introducing new features regularly to expand the app’s capabilities.")}
                        isOpen={isOpenQuestion3}
                        toggleFAQ={() => setOpenQuestion3(!isOpenQuestion3)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("Can I use ENDOless without an Apple Watch?")}
                        text={("Yes! ENDOless integrates with Apple Health, but you don’t need an Apple Watch to track your symptoms or access personalized insights.")}
                        isOpen={isOpenQuestion4}
                        toggleFAQ={() => setOpenQuestion4(!isOpenQuestion4)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("How does ENDOless help with my doctor’s appointments?")}
                        text={t("Our detailed health reports summarize your symptoms, triggers, and patterns, making it easier to communicate with your doctor and save time during consultations.")}
                        isOpen={isOpenQuestion5}
                        toggleFAQ={() => setOpenQuestion5(!isOpenQuestion5)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("Is ENDOless available outside France?")}
                        text={t("ENDOless was built in France but is designed for women worldwide. We are gradually rolling out the app, so check availability in your region.")}
                        isOpen={isOpenQuestion6}
                        toggleFAQ={() => setOpenQuestion6(!isOpenQuestion6)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                    />
                    <Element
                        title={t("Is this app a replacement for medical treatment?")}
                        text={t("No. ENDOless is a management tool that helps you understand and track your condition while improving your quality of life with personalized insights. It’s designed to complement, not replace, medical care.")}
                        isOpen={isOpenQuestion7}
                        toggleFAQ={() => setOpenQuestion7(!isOpenQuestion7)}
                        styles={{
                            width: isFullWidth ? "75%" : "100%"
                        }}
                        isLast={true}
                    />
                </QuestionsContainer>
            </Container>
        </Section>
    )
}

const Section = styled("section")`
    padding: 6.75em 2.5em;
    background: #F2ECFE;

    @media (max-width: 768px) {
        padding: 2.75em 1.5em;
    }
`

const Container = styled("div")`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 2rem;
    }
`
const Title = styled("p")`
    margin: 0;
    color: #1C1C1C;
    font-family: Exo;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 2.125rem;
    }
`
const ISpan = styled("span")`
    font-style: italic;
`
const Image = styled("img")`
    align-self: flex-start;
    margin-top: 1rem;
    margin-left: -3rem;

    @media (max-width: 768px) {
        margin-left: -1.5rem;
        margin-top: 1.5rem;
    }
`
const QuestionsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 0.5;

    @media (max-width: 768px) {
        flex-grow: initial;
    }
`

export default Questions;