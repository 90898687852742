import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { LocalizationProvider } from './utils/LocalizationContext';
import './index.css'
import Header from '../src/components/header/Header';
import Footer from './components/footer/footer';
import Cookies from './components/cookies/cookies';
import App from './App';
import Team from '../src/pages/Team/Team';
import Product from '../src/pages/Product';
import Contact from '../src/pages/Contact';
import ApplicationSecurity from '../src/pages/ApplicationSecurity';
import SoftwarePrinciples from '../src/pages/SoftwarePrinciples';
import LegalInformation from './pages/LegalInformation';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/team/",
    element: <Team />,
  },
  {
    path: "/contact/",
    element: <Contact />,
  },
  {
    path: "/product/",
    element: <Product />,
  },
  {
    path: "/application-security/",
    element: <ApplicationSecurity />,
  },
  {
    path: "/software-principles/",
    element: <SoftwarePrinciples />,
  },
  {
    path: "/legal-information/",
    element: <LegalInformation />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LocalizationProvider>
      <Header />
      <main>
        <RouterProvider router={router} />
        <Cookies />
      </main>
      <Footer />
      <Analytics />
    </LocalizationProvider>
  </React.StrictMode>
);

