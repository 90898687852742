import React from "react";
import styled from "styled-components";
import { useLocalization  } from "../../utils/LocalizationContext";

function Footer() {
    const { t, setLanguage } = useLocalization();
    const nowYear = new Date().getFullYear()
    return (
        <StyledFooter>
            <LogoContainer>
                <img 
                    src="/img/svg/logo.svg"
                    width="192px"
                    height="64px"
                    alt=""
                />
                <Switcher>
                    <P onClick={() => setLanguage("en")}>EN</P>
                    <P onClick={() => setLanguage("fr")}>FR</P>
                </Switcher>
            </LogoContainer>
            <LinksContainer>
                <SocialMedia>
                    <TitleContainer>
                        <Title>{t("Your ")}<ISpan>{t("personalized")}</ISpan></Title>
                        <Title><ISpan>{t("endometriosis")}</ISpan></Title>
                        <Title>{t("journey")}</Title>
                    </TitleContainer>
                    <Icons>
                        <ImgLink href="https://www.instagram.com/endoless_app/" target="_blank" rel="noopener noreferrer">
                            <img 
                                src="/img/svg/inst.svg"
                                width=""
                                height=""
                                alt=""
                            />
                        </ImgLink>
                        <ImgLink href="https://www.tiktok.com/@endoless.app" target="_blank" rel="noopener noreferrer">
                            <img 
                                src="/img/svg/tiktok.svg"
                                width=""
                                height=""
                                alt=""
                            />
                        </ImgLink>
                        <ImgLink href="https://www.linkedin.com/company/endoless/" target="_blank" rel="noopener noreferrer">
                            <img 
                                src="/img/svg/linkedin.svg"
                                width=""
                                height=""
                                alt=""
                            />
                        </ImgLink>
                    </Icons>
                </SocialMedia>
                <Links>
                    <Column>
                        <TitleSmall>{t("Product")}</TitleSmall>
                        <Link></Link>
                    </Column>
                    <Column>
                        <TitleSmall>{t("Team")}</TitleSmall>
                        <Link href="/team/" target="_blank" rel="noopener noreferrer">{t("Our team")}</Link>
                    </Column>
                    <Column>
                        <TitleSmall>{t("More")}</TitleSmall>
                        <Link href="mailto:contact@endoless.app">{t("Let's chat")}</Link>
                        <Link href="/software-principles/" target="_blank" rel="noopener noreferrer">{t("Software Principles")}</Link>
                        <Link href="/application-security/" target="_blank" rel="noopener noreferrer">{t("Application Security")}</Link>
                    </Column>
                </Links>
            </LinksContainer>
            <PoliciesContainer>
                <Year>{`©${nowYear} `}{t("ENDOless. Made with love in Paris ❤️")}</Year>
                <PrivacyLink href="/legal-information/" target="_blank" rel="noopener noreferrer">{t("Legal Information")}</PrivacyLink>
            </PoliciesContainer>
        </StyledFooter>
    )
}

const StyledFooter = styled("footer")`
    padding: 2.5rem;
    opacity: 0.81;
    background: #685ADD;

    @media (max-width: 768px) {
        padding: 1.5rem;
    }
`
const LogoContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #FFF;
`
const LinksContainer = styled("div")`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`
const SocialMedia = styled("div")`
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;

    @media (max-width: 768px) {
        margin-bottom: 2rem;
    }
`
const TitleContainer = styled("div")`
    margin: 1.75rem 0;
`
const Title = styled("p")`
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: Exo;
    font-size: 2.36719rem;
    font-weight: 400;
    line-height: 3.3rem;
    letter-spacing: -0.018rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`
const ISpan = styled("span")`
    font-style: italic;
`
const Icons = styled("div")`
    display: flex;
    align-items: center;
    gap: 1.75rem;
`
const ImgLink = styled("a")``
const Links = styled("div")`
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`
const Column = styled("div")`
    display: flex;
    flex-direction: column;
`
const TitleSmall = styled("p")`
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: Exo;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35rem;
    margin-bottom: 1rem;
`
const Link = styled("a")`
    color: #FFF;
    font-family: Exo;
    font-size: 1.10744rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35rem;
    opacity: 0.6;
    margin-bottom: 1rem;
`
const PoliciesContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Year = styled("div")`
    color: #FFF;
    font-family: Exo;
    font-size: 0.86131rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.9625rem;
    letter-spacing: 0.0875rem;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: .75rem;
    }
`
const PrivacyLink = styled("a")`
    color: #FFF;
    font-family: Exo;
    font-size: 1.10744rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35rem;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: .75rem;
    }
`
const Switcher = styled("div")`
    display: flex;
    gap: 1rem;
`
const P = styled("p")`
    color: #FFF;
    font-family: Exo;
    font-size: 1.10744rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35rem;
    text-transform: uppercase;
    cursor: pointer;
`
export default Footer;