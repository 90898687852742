import React from "react";
import styled from "styled-components";

function Employe({ person }) {
    return (
        <Person>
            <Photo
                src={person.photo}
                width=""
                height=""
                alt={person.name}
            />
            <Name>{person.name}</Name>
            <Position>{person.position}</Position>
        </Person>
    )
}

const Person = styled("div")`
    display: flex;
    flex-direction: column;
`
const Photo = styled("img")`
    width: 95%;
`
const Name = styled("p")`
    margin: 0;
    padding: 0 .5rem;
    color: #252525;
    font-family: Exo;
    font-size: 1.10925rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
`
const Position = styled("p")`
    margin: 0;
    padding: 0 .5rem;
    color: #685ADD;
    font-family: Exo;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.01681rem;
`
export default Employe;