import React from "react";
import styled from "styled-components";

function Text({ text, isFirst }) {
    return(
        <>
            <P $isFirst={isFirst}>{text}</P>
        </>
    )
}

const P = styled("p")`
    padding: 0;
    margin: ${props => props?.$isFirst ? "0" : "1rem 0"};
    color: #000;
    font-family: Exo;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export default Text;