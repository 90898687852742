import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

function Statistics() {
  const { t } = useLocalization()

  return (
    <Section>
      <TextArea>
        <Text>
            {t("Endometriosis affects")}
        </Text>
        <Text>
            <ColoredText>{t("more women than diabetes")}</ColoredText>,
        </Text>
        <Text>
        {t("yet it remains a hidden condition —")}
        </Text>
        <Text>
            <ColoredText>{t("ignored, misunderstood, ")}</ColoredText>{t("and")}
        </Text>
        <Text>
            <ColoredText>{t("underdiagnosed")}</ColoredText>
        </Text>
      </TextArea>
      <StatisticsArea>
        <Numbers1>
            <Number>{t("190M")}</Number>
            <Description>{t("Women worldwide")}</Description>
            <Description>{t("live with endometriosis")}</Description>
        </Numbers1>
        <Numbers2>
            <Number>{t("€6,298")}</Number>
            <Description>{t("The annual")}</Description>
            <Description>{t("Socioeconomic impact per woman.")}</Description>
        </Numbers2>
        <Numbers3>
            <Number>{t("7 Years")}</Number>
            <Description>{t("It takes")}</Description>
            <Description>{t("to receive a diagnosis")}</Description>
        </Numbers3>
        <Numbers4>
            <Number>{t("0 Cures")}</Number>
            <Description>{t("Lead to healing")}</Description>
        </Numbers4>
      </StatisticsArea>
      <EmptyArea />
      <MiniTextArea>
        <Image 
            src="/img/svg/lightGreenLine.svg"
            width=""
            height=""
        />
        <Sentense>
            {t("It’s time to ")}<ColoredText>{t("change that.")}</ColoredText>
        </Sentense>
      </MiniTextArea>
      <EmptyArea2 />
    </Section>
  );
}
  
const Section = styled("section")`
    margin-top: 10rem;
    margin-bottom: 6rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto auto;
    grid-auto-columns: 1fr;
    gap: 8em 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "text empty"
      "statistics empty"
      "empty-2 mini-text";

    @media (max-width: 768px) {
        margin-top: 3rem;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
`
const TextArea = styled("div")`
    grid-area: text;
`
const Text = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1875rem;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        font-size: 2.25rem;
    }
`
const ColoredText = styled("span")`
    color: #685ADD;
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1875rem;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        font-size: 2.25rem;
    }
`
const StatisticsArea = styled("div")`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2em 6.5em;
    grid-auto-flow: row;
    grid-template-areas:
      "number-1 number-2"
      "number-3 number-4";
    grid-area: statistics;

    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
    }
`

const Numbers1 = styled("div")`
    grid-area: number-1; 
`
const Numbers2 = styled("div")`
    grid-area: number-2; 

`
const Numbers3 = styled("div")`
    grid-area: number-3; 
`
const Numbers4 = styled("div")`
    grid-area: number-4; 
`
const Number = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165rem;
    margin: 0;
    padding: 0;
`
const Description = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: -0.03375rem;
    margin: 0;
    padding: 0;
`
const EmptyArea = styled("div")`
    grid-area: empty;
`
const MiniTextArea = styled("div")`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "image"
      "sentense";
    grid-area: mini-text;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`
const Image = styled("img")`
    grid-area: image;
    margin-bottom: 2rem;
    margin-right: -2.5rem;
`
const Sentense = styled(Text)`
    grid-area: sentense;

`
const EmptyArea2 = styled("div")`
    grid-area: empty-2;
`

export default Statistics;