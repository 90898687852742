import React from "react";
import styled from "styled-components";
import { useLocalization } from "../../../../utils/LocalizationContext";

function Warrior() {
    const { t } = useLocalization()

    return (
        <Section>
            <Img 
                src="/img/team/sasha.png"
            />
            <Content>
                <TextContainer>
                    <WarriorText $bolded={true}>{t("Meet  Alexandra")}</WarriorText>
                    <WarriorText>{t("ENDOless Founder and ENDO Warrior")}</WarriorText>
                </TextContainer>
                <ImgSvg 
                    src="/img/svg/yellowLine.svg"
                    width=""
                    height=""
                    alt=""
                />
            </Content>
        </Section>
    )
}

const Section = styled("section")`
    display: flex;
    padding: 0 2.5rem;
    padding-bottom: 5.25rem;
    background: #F2ECFE;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0 .5rem;
    }
`
const Img = styled("img")`
    margin-right: 2rem;
    flex-shrink: 0;

    @media (max-width: 430px) {
        margin-right: 0;
        width: 95%;
    }
`
const Content = styled("div")`
    display: flex;
    flex-direction: column;
    border-radius: 2.625rem;
    background: #685ADD;
    flex-grow: 1;
    height: 36.25rem;
`
const TextContainer = styled("div")`
    padding: 5rem 6rem;
    
    @media (max-width: 430px) {
        padding: 2.5rem 3rem;
    }
`
const WarriorText = styled("p")`
    color: #FFF;
    font-family: Exo;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: ${props => props?.$bolded ? "600" : "400"};
    line-height: normal;
    margin: 0;
`
const ImgSvg = styled("img")`
    width: 70%;
    align-self: flex-end;
`

export default Warrior;